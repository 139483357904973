<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="From Date"
            rules="required"
          >
            <b-form-group
              label="From Date"
              label-for="From Date"
            >
              <b-form-input
                id="from-date"
                v-model="scheduleForm.from"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="From Date"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="To Date"
            rules="required"
          >
            <b-form-group
              label="To Date"
              label-for="To Date"
            >
              <b-form-input
                id="to-date"
                v-model="scheduleForm.to"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="To Date"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="status"
              label-for="status"
            >
              <b-form-input
                id="status"
                v-model="scheduleForm.status"
                :state="getValidationState(validationContext)"
                trim
                placeholder="status"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="user"
          >

            <b-form-group
              label="User "
              label-for="User"
            >
              <b-form-select
                id="user"
                v-model="scheduleForm.user_token"
                :options="optionsUser"
                trim
                placeholder="user"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-textarea
                id="description"
                v-model="scheduleForm.description"
                :state="getValidationState(validationContext)"
                trim
                placeholder="description"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addSchedule()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addSchedule()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      optionsUser: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const scheduleForm = ref({
      user_token: '',

    })
    return {
      getValidationState,
      scheduleForm,
    }
  },
  mounted() {
    this.getUser()
    this.showSchedule()
  },
  methods: {

    showSchedule() {
      if (this.$route.params.id) {
        axios.get(`employee-schedule/${this.$route.params.id}`).then(res => {
          this.scheduleForm = decryptData(res.data.payload).data.Schedule
          this.scheduleForm.user_token = decryptData(res.data.payload).data.Schedule.user.token
        })
      } else {
        return false
      }
      return true
    },
    getUser() {
      this.loader = true
      axios.get('register/all').then(res => {
        this.optionsUser = decryptData(res.data.payload).data.users.map(item => ({
          value: item.token,
          text: item.name,
        }))
      }).finally(() => {
        this.loader = false
      })
    },

    addSchedule() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.scheduleForm) {
          formData.append(key, this.scheduleForm[key])
        }

        axios.post(`employee-schedule/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'schedule' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.scheduleForm) {
          formData.append(key, this.scheduleForm[key])
        }

        axios.post('employee-schedule', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'schedule' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
